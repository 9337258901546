<template>
    <div>
        <pages :total="titles" :pageSize="pageSize" @ClickChange="ClickChange">

        </pages>
    </div>
</template>

<script>
import pages from '../components/pagetion/pagetion'
export default {
    data() {
        return {
            titles:12,
            pageSize:3
        };
    },
components:{
    pages
},
    created() {

    },
    mounted() {

    },
    methods: {
    }
};
</script>

<style scoped>

</style>
